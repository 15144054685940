import './lib/common.js'; // Активация jQuery вне Webpack, создание глобальной переменной $ 
import maskPlugin from 'jquery-mask-plugin'; // Маска телефона
import fancybox from '@fancyapps/fancybox'; // Модальные окна
import lazyload from 'lazyload'; // Подгрузка изображений
import Swiper from 'swiper'; // Карусель
import { WOW } from 'wowjs'; // WOW

new WOW().init();

// Modals
$('[data-fancybox]').fancybox({
  loop     : true,
  autoFocus: false,
  touch: {
    vertical: false
  },
  buttons: [
    'zoom',
    'close'
  ],
});

$('.lazyload').lazyload();
$('.faq img').lazyload();

// CAROUSEL
var swiper = () => {
  new Swiper('.team .swiper-container', {
    init: true,
    speed: 400,
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 3.5,
    parallax: true,
    lazy: {
      loadedClass: 'loaded'
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      snapOnRelease: true,
      dragSize: 42
    },
    breakpoints: {
      0: {
        slidesPerView: 1.2
      },
      576: {
        slidesPerView: 1.8
      },
      768: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 3
      }
    }
  });

  new Swiper('.gallery .swiper-container', {
    init: true,
    speed: 400,
    spaceBetween: 20,
    freeMode: true,
    slidesPerView: 3.5,
    parallax: true,
    lazy: {
      loadedClass: 'loaded'
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      snapOnRelease: true,
      dragSize: 42
    },
    breakpoints: {
      0: {
        slidesPerView: 1.2
      },
      576: {
        slidesPerView: 1.8
      },
      768: {
        slidesPerView: 2.5
      },
      992: {
        slidesPerView: 3.5
      },
      1200: {
        slidesPerView: 4.5
      }
    }
  });
};

swiper();


// Click Tracking
$('[data-click]').on('click', function() {
  let click = $(this).data('click');
  fbq('trackCustom',click);
  // yaCounter54652267.reachGoal(click);
});




// Вставка в форму комментария от нажатой кнопки
// открывающей модальное окно
$('.button[data-button]').on('click', function() {
  let button = $(this).data('button');
  $('form .hidden input[name="button"]').remove();
  $('form .hidden').append('<input type="hidden" name="button" value="'+button+'">');
});

// Маска телефона, если телефон не заполнен до конца,
// то при нажатии куда либо сбрасывает введенное в инпут
let maskPhone = '+38 000 000-00-00';
$('input[name=phone]').mask(maskPhone).focusout(function() {
  let p = $(this);
  if (p.val().length < maskPhone.length) {
    p.val('');
  }
});

// Отработка формы, проверка на пустые поля 
var params = window.location.search.replace('?','').split('&').reduce(
  function(p,e) {
    var a = e.split('=');
    p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
    return p;
  }, {});

$('form').each(function() {
  let th = $(this).append('<div class="utm" hidden>').find('.utm');
  $.each(params, function(key, value) {
    if (key !== 0) {
      th.append('<input name="'+key+'" value="'+value+'" hidden>');
    }
  });
});

$(function() {
  $('form').each(function() {
    // Объявляем переменные (форма и кнопка отправки)
    let form = $(this),
      btn = form.find('.button-submit');

    // Добавляем каждому проверяемому полю, указание что поле пустое
    form.find('.field-input').addClass('empty-field');

    // Функция проверки полей формы
    function checkInput() {
      form.find('.field-input').each(function() {
        if($(this).val() !== '') {
          // Если поле не пустое удаляем класс-указание
          $(this).removeClass('empty-field');
        } else {
          // Если поле пустое добавляем класс-указание
          $(this).addClass('empty-field');
        }
      });
    }

    // Функция подсветки незаполненных полей
    function lightEmpty() {
      form.find('.empty-field').parent().addClass('empty-field');
      // Через полсекунды удаляем подсветку
      setTimeout(function() {
        form.find('.empty-field').parent().removeClass('empty-field');
      },1000);
    }

    // Функция отправки формы 
    // Нужно написать ajax функцию отправки формы
    function fromSubmit() {
      var phone = form.find('input[name=phone]').val();
      btn.prop('disabled', true);
      form.submit();

      $(document).on('af_complete', function(event, response) {

        var form = response.form;

        form.trigger('reset');
        btn.prop('disabled', false);
        
        $.fancybox.close();

        $.fancybox.open({
          src  : '#thanks',
          type : 'inline',
          buttons: [
            'close'
          ],
          afterLoad: function() {
            $('.check-phone').empty().append(phone);
          }
        });

        // Отслеживание лидов
        dataLayer.push({'event':'send_request'});
        fbq('track','Lead');
        // yaCounter34842275.reachGoal('lead_form_order');
      });

    }

    // Проверка в режиме реального времени
    setInterval(function() {
      // Запускаем функцию проверки полей на заполненность
      checkInput();
      // Считаем к-во незаполненных полей
      let sizeEmpty = form.find('.empty-field').length;

      // Вешаем условие-тригер на кнопку отправки формы
      if(sizeEmpty > 0) {
        if(btn.hasClass('disabled')) {
          return false;
        } else {
          btn.addClass('disabled');
        }
      } else {
        btn.removeClass('disabled');
      }
    },500);

    // Событие клика по кнопке отправить
    btn.click(function() {
      if($(this).hasClass('disabled')) {
        // подсвечиваем незаполненные поля и форму не отправляем, если есть незаполненные поля
        lightEmpty();
        return false;
      } else {
        // Все хорошо, все заполнено, отправляем форму
        fromSubmit();
      }
    });
  });
});





// Accordion
$(document).ready(function() {
  //Add Inactive Class To All Accordion Headers
  $('.accordion-header').toggleClass('inactive-header');
	
  //Set The Accordion Content Width
  // var contentwidth = $('.accordion-header').width();
  // $('.accordion-content').css({'width' : contentwidth });
	
  //Open The First Accordion Section When Page Loads
  // $('.accordion-header').first().toggleClass('active-header').toggleClass('inactive-header');
  // $('.accordion-content').first().slideDown().toggleClass('open-content');
	
  // The Accordion Effect
  $('.accordion-header').click(function() {
    let th = $(this);
    var scrollTo = th.offset().top;
    console.log(scrollTo);
    // if(th.is('.inactive-header')) {
    //   $('.active-header').toggleClass('active-header').toggleClass('inactive-header').next().slideToggle().toggleClass('open-content');
    //   th.toggleClass('active-header').toggleClass('inactive-header');
    //   th.next().slideToggle().toggleClass('open-content');
    // } else {
    //   th.toggleClass('active-header').toggleClass('inactive-header');
    //   th.next().slideToggle().toggleClass('open-content');
    // }
    th.toggleClass('active-header').toggleClass('inactive-header');
    th.next().slideToggle().toggleClass('open-content');
    
  });
	
  return false;
});



$('.tab-link').click(function(e) {
  var s = $(this).parents('.tabs-wrapper'),
    i = s.data('delay'),
    o = $(this).attr('href');
  $('.tabs-wrapper').find('.tab-link').removeClass('active'),
  $(this).addClass('active'), 
  s.find('.tab-block').removeClass('_tab-anim visible'),
  setTimeout(function() {
    s.find('.tab-block').removeClass('active'), 
    $(o).addClass('active _tab-anim');
    $('body,html').animate({scrollTop: s.offset().top }, 500);
  }, i), e.preventDefault();
});

$('.tabs-wrapper').each(function() {
  $(this).find('.tab-link').first().addClass('active');
  $(this).find('.tab-block').first().addClass('active');
});

// Паралакс
$(window).mousemove(function(e) {
  var change;
  var xpos=e.clientX;var ypos=e.clientY;var left= change*20;
  var xpos=xpos*2;ypos=ypos*2;
  var prx = $('.background_petal');
  var prxSwing = prx.data('swing');
  prx.css('top',((0+(ypos/prxSwing))+'px')).css('right',(( 0+(xpos/prxSwing))+'px'));
  // console.log(xpos, ypos);               
});
